<template>
  <div class="communication-list-page w-100">
    <basic-subheader>
      <template v-slot:actions>
        <b-dropdown
          id="dropdown-right dropdown-form"
          class="dropdown-form-filter"
          no-caret
          right
        >
          <template #button-content>
            <span class="svg-icon">
              <inline-svg src="/media/svg/icons/Neolex/Basic/filter.svg" />
            </span>
            Bộ lọc
          </template>
          <h6 class="d-flex align-items-center mb-0 p-4">
            <span class="svg-icon mr-3">
              <inline-svg src="/media/svg/icons/Neolex/Basic/filter.svg" />
            </span>
            Bộ lọc
          </h6>
          <b-dropdown-divider> </b-dropdown-divider>
          <b-dropdown-form>
            <b-container class="p-0" style="min-width: 750px">
              <b-row>
                <b-col>
                  <b-row>
                    <b-col>
                      <basic-input
                        label="Mã / Tiêu đề thông báo"
                        placeholder="Nhập mã hoặc tiêu đề thông báo "
                        name="codeOrTitle"
                        :value.sync="filter.code"
                      ></basic-input>
                    </b-col>
                  </b-row>

                  <b-row>
                    <b-col>
                      <b-row>
                        <b-col class="py-0">
                          <label for="sendDate">Ngày gởi</label>
                        </b-col>
                      </b-row>
                      <b-row>
                        <b-col class="py-0">
                          <basic-date-picker
                            placeholder="Nhập từ ngày gởi"
                            name="startDateTimeToSend"
                            :value.sync="filter.sentDateStart"
                          />
                        </b-col>
                        <b-col class="py-0">
                          <basic-date-picker
                            placeholder="Nhập đến ngày gởi"
                            name="endDateTimeToSend"
                            :value.sync="filter.sentDateEnd"
                          />
                        </b-col>
                      </b-row>
                    </b-col>
                  </b-row>
                  <b-row>
                    <b-col>
                      <basic-select
                        label="Người tạo"
                        placeholder="--- Chọn người tạo ---"
                        name="messageType"
                        :options="creators"
                        :value.sync="filter.creatorId"
                        :solid="false"
                        :allowEmpty="true"
                      />
                    </b-col>
                  </b-row>
                  <b-row>
                    <b-col>
                      <basic-select
                        label="Loại Message"
                        placeholder="--- Chọn loại message ---"
                        name="objectGroupType"
                        :options="messageOpts"
                        :value.sync="filter.messageType"
                        :solid="false"
                        :allowEmpty="true"
                        changeValueByObject
                      />
                    </b-col>
                  </b-row>
                  <b-row>
                    <b-col>
                      <b-form-checkbox-group
                        v-model="filter.inactive"
                        :options="[{ text: 'Lọc Inactive', value: false }]"
                      ></b-form-checkbox-group>
                    </b-col>
                  </b-row>
                </b-col>
                <b-col>
                  <b-row>
                    <b-col>
                      <basic-select
                        label="Trạng thái"
                        placeholder="--- Chọn trạng thái ---"
                        name="status"
                        :options="statusOpts"
                        :value.sync="filter.status"
                        :solid="false"
                        :allowEmpty="true"
                      />
                    </b-col>
                  </b-row>

                  <b-row>
                    <b-col>
                      <b-row>
                        <b-col class="py-0">
                          <label for="sendDate">Ngày tạo</label>
                        </b-col>
                      </b-row>
                      <b-row>
                        <b-col class="py-0">
                          <basic-date-picker
                            placeholder="Nhập từ ngày tạo"
                            name="startDateTimeToSend"
                            :value.sync="filter.createDateStart"
                          />
                        </b-col>
                        <b-col class="py-0">
                          <basic-date-picker
                            placeholder="Nhập đến ngày tạo"
                            name="endDateTimeToSend"
                            :value.sync="filter.createDateEnd"
                          />
                        </b-col>
                      </b-row>
                    </b-col>
                  </b-row>
                  <b-row>
                    <b-col>
                      <basic-select
                        label="Loại nhóm đối tượng"
                        placeholder="--- Chọn loại nhóm đối tượng ---"
                        name="messageType"
                        :options="[]"
                        :value.sync="filter.groupType"
                        disabled
                        changeValueByObject
                        :solid="false"
                        :allowEmpty="true"
                      />
                    </b-col>
                  </b-row>
                </b-col>
              </b-row>
              <!--  -->
            </b-container>
          </b-dropdown-form>
          <b-dropdown-divider> </b-dropdown-divider>
          <div class="d-flex align-items-center justify-content-lg-end m-0 p-4">
            <b-button
              class="btn ml-2"
              href="#"
              tabindex="0"
              @click.prevent="resetRequest"
            >
              <span class="svg-icon">
                <inline-svg
                  src="/media/svg/icons/Neolex/Basic/refresh-cw.svg"
                />
              </span>
              Reset bộ lọc
            </b-button>
            <b-button
              class="btn ml-2"
              href="#"
              tabindex="0"
              @click.prevent="searchRequest"
            >
              <span class="svg-icon">
                <inline-svg src="/media/svg/icons/Neolex/Basic/filter.svg" />
              </span>
              Lọc dữ liệu
            </b-button>
          </div>
        </b-dropdown>
        <b-button
          v-if="isWritePermission"
          class="btn btn-success ml-2"
          type="button"
          @click="createItem"
        >
          <span class="svg-icon">
            <inline-svg src="/media/svg/icons/Neolex/Basic/plus.svg" />
          </span>
          Thêm thông báo
        </b-button>
      </template>
    </basic-subheader>

    <b-container fluid class="user-app-list-page__body mb-6 mt-6">
      <b-row>
        <b-col>
          <div class="card card-custom gutter-b">
            <div class="card-body mt-0">
              <b-overlay :show="loading">
                <template-table
                  :column="column"
                  :data="data"
                  :paging="paging"
                  :tableAction="false"
                  :selectAction="false"
                  :searchAction="false"
                  @search="searchRequest"
                  @reset="resetRequest"
                  @sortBy="sortRequest"
                >
                  <template v-slot:body="{ item }">
                    <td>
                      {{ item.code }}
                    </td>
                    <td>
                      {{ item.title }}
                    </td>
                    <td>
                      <communication-state :type="item.status.toLowerCase()">
                        {{ item.status }}
                      </communication-state>
                    </td>
                    <td style="width: 20px">
                      <action-dropdown
                        :value="item"
                        @view="viewItem"
                        @edit="editItem"
                        @copy="copyItem"
                        :show_delete="false"
                        :show_edit="isWritePermission"
                        :show_copy="isWritePermission"
                        @delete="deleteItem"
                        :boundary="data.length > 2 ? 'scrollParent' : 'window'"
                      >
                      </action-dropdown>
                    </td>

                    <td>
                      {{
                        `${
                          item.sentDateTime != -62135596800
                            ? $moment(item.sentDateTime * 1000).format(
                                'HH:mm DD/MM/YYYY',
                              )
                            : ''
                        }`
                      }}
                    </td>
                    <td>
                      {{
                        ` ${$moment(item.createDateTime * 1000).format(
                          'DD/MM/YYYY',
                        )}`
                      }}
                    </td>
                    <td>
                      <div class="d-flex align-items-center">
                        <avatar
                          size="40px"
                          :text="item.creator"
                          :src="item.creatorUrl && item.creatorUrl.url"
                          :rounded="true"
                        ></avatar>
                        <div class="d-flex flex-column ml-5">
                          <p
                            class="mb-0"
                            style="
                              font-weight: 600;
                              font-size: 13px;
                              color: #515356;
                            "
                          >
                            {{ item.creator }}
                          </p>
                          <p
                            class="mt-2 mb-0"
                            style="font-size: 12px; color: #888c9f"
                          >
                            <!-- {{ item.createdBy.username }} -->
                          </p>
                        </div>
                      </div>
                    </td>
                  </template>
                </template-table>
              </b-overlay>
            </div>
          </div>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<style lang="scss" scoped>
.dropdown-form-filter {
  .dropdown-menu {
    .container {
      width: 450px;
    }
  }
}
</style>

<script>
import { NOTI_MESS_TYPE } from './mixins/communicationForm';
export default {
  data() {
    return {
      paging: {
        page: 1,
        pageSize: 10,
        total: 0,
      },
      filter: {
        code: null,
        status: null,
        sentDateStart: null,
        sentDateEnd: null,
        createDateStart: null,
        createDateEnd: null,
        messageType: null,
      },
      sort: {
        by: null,
        order: null,
      },
      statusOpts: [
        { id: 0, name: 'Draft' },
        { id: 1, name: 'Active' },
        { id: 2, name: 'Sent' },
        { id: 3, name: 'Recall' },
      ],

      column: [
        {
          key: 'code',
          label: 'Mã',
          sortable: true,
        },
        {
          key: 'title',
          label: 'Tiêu đề thông báo',
          sortable: false,
        },
        {
          key: 'status',
          label: 'Trạng Thái',
          sortable: false,
        },
        {
          key: 'actionDropdown',
          label: '',
          sortable: false,
        },

        {
          key: 'sentDateTime',
          label: 'Thời gian gởi',
          sortable: true,
        },
        {
          key: 'createdAt',
          label: 'Ngày tạo',
          sortable: false,
        },
        {
          key: 'createdBy',
          label: 'Người tạo',
          sortable: false,
        },
      ],
      data: [],
      creators: [],
      loading: false,
      messageOpts: [
        { name: 'Marketing', value: NOTI_MESS_TYPE.MARKETING },
        { name: 'Tạo tài khoản', value: NOTI_MESS_TYPE.CREATE_ACCOUNT },
        { name: 'Chúc mừng sinh nhật', value: NOTI_MESS_TYPE.HAPPY_BIRTHDAY },
        {
          name: 'Tham gia gói dịch vụ thành công',
          value: NOTI_MESS_TYPE.JOIN_SERVICE_SUCCESS,
        },
        { name: 'Phân vào nhóm', value: NOTI_MESS_TYPE.JOIN_GROUP },
        {
          name: 'Mục tiêu ngày - đầu ngày',
          value: NOTI_MESS_TYPE.TARGET_START_DAY,
        },
        {
          name: 'Mục tiêu ngày - cuối ngày',
          value: NOTI_MESS_TYPE.TARGET_END_DAY,
        },
        {
          name: 'Mục tiêu tuần - đầu tuần',
          value: NOTI_MESS_TYPE.TARGET_START_WEEK,
        },
        {
          name: 'Mục tiêu tuần - cuối tuần',
          value: NOTI_MESS_TYPE.TARGET_END_WEEK,
        },
        {
          name: 'Lịch hẹn huấn luyện viên - 30 phút',
          value: NOTI_MESS_TYPE.COACH_APPT_30_MINUTE,
        },
        {
          name: 'Lịch hẹn huấn luyện viên - 1 ngày',
          value: NOTI_MESS_TYPE.COACH_APPT_1_DAY,
        },
        { name: 'Activity', value: NOTI_MESS_TYPE.ACTIVITY },
        { name: 'Khảo sát', value: NOTI_MESS_TYPE.SURVEY },
      ],
    };
  },
  computed: {
    searchParams() {
      return {
        name: this.filter.codeOrTitle,
        orderBy: this.sort.by ? `${this.sort.by} ${this.sort.order}` : null,
        page: this.paging.page,
        size: this.paging.pageSize,
      };
    },
    createDateStart() {
      if (this.filter.createDateStart) {
        return this.$moment(this.filter.createDateStart).format('X');
      } else {
        return null;
      }
    },
    createDateEnd() {
      if (this.filter.createDateEnd) {
        return this.$moment(this.filter.createDateEnd).format('X');
      } else {
        return null;
      }
    },
    sentDateStart() {
      if (this.filter.sentDateStart) {
        return this.$moment(this.filter.sentDateStart).format('X');
      } else {
        return null;
      }
    },
    sentDateEnd() {
      if (this.filter.sentDateStart) {
        return this.$moment(this.filter.sentDateEnd).format('X');
      } else {
        return null;
      }
    },
    isWritePermission() {
      return this.$route.meta.isWritePermission;
    },
  },
  watch: {
    'paging.page': {
      handler() {
        this.loadData();
      },
      deep: true,
    },
    'paging.size': {
      handler() {
        this.loadData();
      },
      deep: true,
    },
    sort: {
      handler() {
        this.loadData();
      },
      deep: true,
    },
  },
  methods: {
    pagingAction() {
      this.loadData();
    },
    createItem() {
      this.$router.push({
        name: 'communication_create',
        params: {
          form_type: 'create',
        },
      });
    },

    searchRequest() {
      this.loadData();
      document.body.click();
    },

    resetRequest() {
      this.filter.code = null;
      this.filter.status = null;
      this.filter.messageType = null;
      this.filter.creatorId = null;
      this.filter.createDateStart = null;
      this.filter.createDateEnd = null;
      this.filter.sentDateStart = null;
      this.filter.sentDateEnd = null;
      this.filter.inactive = [];
      this.$nextTick(() => {
        this.$validator.reset();
      });
      this.loadData();
      document.body.click();
    },
    sortRequest(sortData) {
      this.sort = {
        by: sortData.column,
        order: sortData.order,
      };
      return;
    },
    viewItem(item) {
      this.$router.push({
        name: 'communication_detail',
        params: {
          form_type: 'detail',
          id: item.id,
        },
      });
    },
    editItem(item) {
      this.$router.push({
        name: 'communication_create',
        params: {
          form_type: 'edit',
          id: item.id,
        },
      });
    },
    copyItem(item) {
      this.$router.push({
        name: 'communication_create',
        params: {
          form_type: 'copy',
          id: item.id,
        },
      });
    },
    async deleteItem(item) {
      try {
        await this.$api.delete(`Communication/Input/${item.id}`, {
          headers: {
            'Content-Type': 'application/json',
          },
        });
        this.loadData();
        this.$toastr.s('Cập nhật thành công', 'SUCCESS');
      } catch (error) {
        this.$toastr.e('Cập nhật thất bại', 'ERROR');
      }
    },
    async loadCreators() {
      try {
        const { data } = await this.$api.get('Communication/CreatorList');
        let creator = data.map((e) => ({
          name: e.creatorName,
          id: e.creatorId,
        }));
        this.creators = creator || [];
      } catch (error) {
        this.$toastr.e({
          title: 'Lỗi!',
          msg: error,
        });
      }
    },
    loadData() {
      this.loading = true;
      // this.$store.commit('context/setLoading', true);
      let sentDateStart = this.sentDateStart;
      let sentDateEnd = this.sentDateEnd;
      let createDateStart = this.createDateStart;
      let createDateEnd = this.createDateEnd;
      let inactive = this.filter.inactive?.[0];
      let messageType = this.filter.messageType?.value;
      this.$api
        .get('communication', {
          params: {
            ...this.searchParams,
            ...this.filter,
            inactive: inactive,
            sentDateStart,
            sentDateEnd,
            createDateStart,
            createDateEnd,
            messageType,
          },
        })
        .then(({ meta, data }) => {
          this.data = data || [];
          this.paging.total = meta.total;
          // this.$store.commit('context/setLoading', false);
        })
        .catch((error) => {
          this.$toastr.e({
            title: 'Lỗi',
            msg: error,
          });
        })
        .finally(() => {
          this.loading = false;
          // this.$store.commit('context/setLoading', false);
        });
    },
  },
  created() {
    this.loadData();
    this.loadCreators();
  },
};
</script>
